import { Injectable } from '@angular/core';
import { CommunicationService } from './communication.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  // variables
  public static serviceCount: number = 0;
  public addOne: number = 1;
  public lessOne: number = -1;
  public static showLoader: boolean = false;

  constructor(private communicationService: CommunicationService) { }

  public showLoader() {
    this.handleLoader(this.addOne);
  }

  public hideLoader() {
    this.handleLoader(this.lessOne);
  }

  public handleLoader(count: number) {
    LoaderService.serviceCount = LoaderService.serviceCount + count;
    if (LoaderService.serviceCount > 0) {
      LoaderService.showLoader = true;
      setTimeout(() => {
        this.communicationService.broadcast('show', LoaderService.showLoader);
      }, 10);
    } else {
      LoaderService.showLoader = false;
      setTimeout(() => {
        this.communicationService.broadcast('show', LoaderService.showLoader);
      }, 10);
    }
  }
}
