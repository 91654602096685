import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CidaasService } from 'src/app/services/cidaas.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  // variables
  public resetPasswordForm: FormGroup;
  requestId: string;
  user: any = {};
  isForgetError: boolean = false;
  submitted: boolean = false;

  constructor(private userstoreService: CidaasService, private router: Router, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder) {
    window.document.title = 'Forget Password';
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.requestId = params['requestId'];
    });
    this.createResetForm();
  }

  createResetForm() {
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  onReset(formValue: any, valid: boolean) {
    this.submitted = true;
    if (valid) {
      this.user.email = formValue.value.email;
      this.user.processingType = 'LINK';
      this.user.requestId = this.requestId;
      this.userstoreService.initiateResetPassword(this.user).then((response: any) => {
        if (response) {
          this.submitted = false;
          this.router.navigate(['/reset_success'], { queryParams: { requestId: this.requestId } });
        } else {
          this.isForgetError = true;
        }
      }).catch(() => {
        this.submitted = false;
        this.isForgetError = true;
      });
    }
  }

}
