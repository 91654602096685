import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routes } from './config/routes';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { LoaderComponent } from './views/loader/loader.component';
import { MfaComponent } from './views/mfa/mfa.component';
import { MfaVerifyComponent } from './views/mfa-verify/mfa-verify.component';
import { ExtraComponent } from './views/extra/extra.component';
import { ForgetPasswordComponent } from './views/forget-password/forget-password.component';
import { ForgetSuccessComponent } from './views/forget-success/forget-success.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { ConsentComponent } from './views/consent/consent.component';
import { ScopeConsentComponent } from './views/scope-consent/scope-consent.component';
import { RegisterSuccessComponent } from './views/register-success/register-success.component';
import { ConfirmEmailComponent } from './views/confirm-email/confirm-email.component';
import { VerificationComponent } from './views/verification/verification.component';
import { ResetSuccessComponent } from './views/reset-success/reset-success.component';
import { UnverifiedUserComponent } from './views/unverified-user/unverified-user.component';

export function createTranslateLoader(http: HttpClient) {
  let currentDate = new Date();
  let today = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
  let version = today.getTime();
  return new TranslateHttpLoader(http, './assets/lang/', '.json?v=' + version + "_001");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    LoaderComponent,
    MfaComponent,
    MfaVerifyComponent,
    ExtraComponent,
    ForgetPasswordComponent,
    ForgetSuccessComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    ConsentComponent,
    ScopeConsentComponent,
    RegisterSuccessComponent,
    ConfirmEmailComponent,
    VerificationComponent,
    ResetSuccessComponent,
    UnverifiedUserComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
