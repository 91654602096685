import { Component, OnInit } from '@angular/core';
import { CidaasService, LoaderService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-scope-consent',
  templateUrl: './scope-consent.component.html',
  styleUrls: ['./scope-consent.component.scss']
})
export class ScopeConsentComponent implements OnInit {

  // Variables
  requestId: string;
  track_id: string;
  category_array: any[];
  language: string;
  localnavigator: any = <any>window.navigator;
  sub: string = "";
  client_id: string = "";

  constructor(private route: ActivatedRoute, private cidaasService: CidaasService, private router: Router, private loader: LoaderService) { }

  ngOnInit() {
    this.requestId = this.route.snapshot.queryParams.requestId;
    this.track_id = this.route.snapshot.queryParams.track_id;
    this.sub = this.route.snapshot.queryParams.sub;
    this.client_id = this.route.snapshot.queryParams.client_id;
    this.language = this.localnavigator.language;
    if (!this.language) {
      this.language = this.localnavigator.systemLanguage;
    }
    if (!this.language) {
      this.language = 'de';
    }
    this.getScopeConsentDetails();
  }

  getScopeConsentDetails() {
    this.loader.showLoader();
    var options = {
      track_id: this.track_id,
      locale: this.language
    };
    this.cidaasService.getScopeConsentDetails(options).then((response: any) => {
      if (response && response.data && response.data.meta_data && response.data.meta_data.scopes && response.data.meta_data.scopes.length > 0) {
        this.category_array = response.data.meta_data.scopes;
      }
      this.loader.hideLoader();
    }).catch(() => {
      this.loader.hideLoader();
    });
  }

  acceptScopeConsent() {
    this.loader.showLoader();
    var scopes: string[] = [];
    if (this.category_array && this.category_array.length > 0) {
      this.category_array.forEach((scope) => {
        scopes.push(scope.scope);
      })
    }
    this.cidaasService.acceptScopeConsent({
      scopes: scopes,
      sub: this.sub,
      client_id: this.client_id
    }).then((response) => {
      this.loader.hideLoader();
      this.consentContinue();
    }).catch(() => {
      this.loader.hideLoader();
    });
  }

  consentContinue() {
    this.loader.showLoader();
    this.cidaasService.scopeConsentContinue({
      track_id: this.track_id
    });
  }
}
