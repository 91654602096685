import { Component, OnInit } from '@angular/core';
import { CidaasService, LoaderService } from 'src/app/services';
import { Router, ActivatedRoute } from '@angular/router';

declare var Fingerprint2: any;

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.scss']
})
export class MfaComponent implements OnInit {

  // variables
  sub: string;
  trackId: string;
  requestId: string;
  alternative: boolean;
  fingerprint: string;
  showList: boolean = false;
  emailUserDeviceId: string;
  statusId: string;
  configureList: any[] = [];
  isTOTPAvailable: boolean = false;

  constructor(private userstoreService: CidaasService, private route: ActivatedRoute, private router: Router, public loader: LoaderService) {
    this.sub = this.route.snapshot.queryParams.sub;
    this.trackId = this.route.snapshot.queryParams.track_id;
    this.requestId = this.route.snapshot.queryParams.requestId;
    this.alternative = Boolean(this.route.snapshot.queryParams.alternative);
  }

  ngOnInit() {
    try {
      new Fingerprint2().get((result, components) => {
        this.fingerprint = result;
        this.getMFAList();
      });
    }
    catch (ex) {
      this.fingerprint = navigator.userAgent;
      this.getMFAList();
    }
  }

  // Get MFA List
  getMFAList() {
    this.loader.showLoader();
    this.showList = false;
    let options: any = {};
    options.request_id = this.requestId;
    options.sub = this.sub;
    this.userstoreService.getMFAList(options).then((response: any) => {
      this.configureList = response.data.configured_list;
      var find = this.configureList.find((c) => c.type == "TOTP");
      if (find) {
        this.isTOTPAvailable = true;
        // this.totpPhysicalVerificationId = find.mediums[0].id;
        // this.totpUserDeviceId = find.mediums[0].id;
        this.showList = false;
      }
      else {
        this.isTOTPAvailable = false;
        this.showList = false;
      }
      var find = this.configureList.find((c) => c.type == "EMAIL");
      if (find) {
        // this.emailPhysicalVerificationId = find._id;
        this.emailUserDeviceId = find.mediums[0].id;
      }

      if (!this.isTOTPAvailable) {
        this.loader.hideLoader();
        this.initiateEmail();
      } else if (this.alternative) {
        this.loader.hideLoader();
        this.initiateEmail();
      } else {
        this.loader.hideLoader();
        this.initiateTotp();
      }
    }).catch((ex) => {
      console.log(ex);
      this.loader.hideLoader();
    });
  }

  // Initialize Email
  initiateEmail() {
    this.loader.showLoader();
    let user: any = {};
    user.sub = this.sub;
    user.medium_id = this.emailUserDeviceId;
    user.request_id = this.requestId;
    user.usage_type = 'MULTIFACTOR_AUTHENTICATION';
    user.type = 'EMAIL';
    this.userstoreService.initiateEmail(user).then((response: any) => {
      if (response) {
        this.statusId = response.exchange_id.exchange_id;
        this.router.navigate(['/mfaverify', this.statusId, 'email', this.sub, this.trackId, this.requestId]);
        this.loader.hideLoader();
      } else {
        this.loader.hideLoader();
      }
    }).catch((ex) => {
      this.loader.hideLoader();
      console.log(ex);
    });
  }

  // Initialize TOTP
  initiateTotp() {
    this.loader.showLoader();
    let user: any = {};
    user.sub = this.sub;
    // user.medium_id = this.totpUserDeviceId;
    user.request_id = this.requestId;
    user.usage_type = 'MULTIFACTOR_AUTHENTICATION';
    user.type = 'TOTP';
    this.userstoreService.initiateTOTP(user).then((response: any) => {
      this.loader.hideLoader();
      if (response.status == 200 && response.data && response.success) {
        this.statusId = response.data.exchange_id.exchange_id;
        this.router.navigate(['/mfaverify', this.statusId, 'totp', this.sub, this.trackId, this.requestId]);
      } else {
        if (response.status == 500) {
          console.error('Internal Server Error!');
        } else {
          console.error(response.error.error);
        }
      }
    }).catch((ex) => {
      this.loader.hideLoader();
      console.log(ex);
    });
  }

}
