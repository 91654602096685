export class StorageHelper {

    // Save Data to Localstorage
    static saveData(key: string, value: string) {
        if (key && value) {
            if (typeof (Storage) !== undefined) {
                localStorage.setItem(key, value);
            } else {
                console.log('upgrade your browser or enable localstorage');
            }
        } else {
            console.log('key or value is undefined!')
        }
    }

    static openNav() {
        document.getElementById("mySidenav").style.width = "380px";
        document.getElementById("mySidenav").style.marginLeft = "-15px";
    }

    // Get Data from Localstorage
    static getData(key: string): string {
        if (typeof (Storage) !== undefined) {
            return localStorage.getItem(key);
        } else {
            return null;
        }
    }

    // Delete Data from Localstorage
    static deleteData(key: string) {
        if (typeof (Storage) !== undefined) {
            localStorage.removeItem(key);
        }
    }

    // Delete All Data from Localstorage
    static deleteAll() {
        if (typeof (Storage) !== undefined) {
            localStorage.clear();
        }
    }

}