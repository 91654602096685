import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CidaasService, LoaderService, TranslationService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordPolicy } from '../../models/index';
import { PasswordPolicyValidation, PasswordValidation, StorageHelper } from '../../config';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  // variables
  passwordPolicy: PasswordPolicy;
  changePasswordForm: FormGroup;
  minlength: number = 0;
  maxlength: number = 100;
  identityId: string;
  loginSettingsId: string;
  requestId: string;
  specialCharactersError: string;
  LowerAndUpperCaseError: string;
  digitsRequiredError: string;
  maxLengthError: string;
  minLengthError: string;
  token: string;
  isChangePasswordError: boolean = false;
  changePasswordError: string;
  errorMessage: string;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute, private changePasswordService: CidaasService, public loader: LoaderService, private translate: TranslationService) {
    window.document.title = 'Change Password';
    this.activatedRoute.params.subscribe(params => {
      this.identityId = params['identityId'];
    });
    if (!this.identityId) {
      this.identityId = 'newUser';
      this.loginSettingsId = this.activatedRoute.snapshot.queryParams.lsid;
      this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
    } else {
      this.getRequestId();
    }
  }

  ngOnInit() {
    this.createNewchangePasswordForm();
    this.getPasswordPolicy();
  }

  // Get Request Id (If Request Id is not came in URL)
  getRequestId() {
    this.changePasswordService.getRequestId().then((response: string) => {
      this.requestId = response;
    }).catch(() => {

    });
  }

  // create new reset password form
  createNewchangePasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      old_password: ['', [Validators.required]],
      password: ['', [Validators.required]],
      password_echo: ['', [Validators.required]]
    });
    this.changePasswordForm.setValidators(PasswordValidation.MatchPassword);
  }

  // Get Password policy
  getPasswordPolicy() {
    this.loader.showLoader();
    this.changePasswordService.getPasswordPolicy().then((response: any) => {
      this.passwordPolicy = response.data;
      if (this.passwordPolicy.minimumLength > 0) {
        this.minlength = this.passwordPolicy.minimumLength;
      }
      if (this.passwordPolicy.maximumLength > 0) {
        this.maxlength = this.passwordPolicy.maximumLength;
      }
      this.specialCharactersError = this.translate.getTranslatedMessage('Password must contain atleast ') + this.passwordPolicy.noOfSpecialChars + this.translate.getTranslatedMessage(' Special Characters');
      this.digitsRequiredError = this.translate.getTranslatedMessage('Password must contain atleast ') + this.passwordPolicy.noOfDigits + this.translate.getTranslatedMessage(' Digits');
      this.LowerAndUpperCaseError = this.translate.getTranslatedMessage('Password must contain atleast one uppercase and one lower case Character');
      this.minLengthError = this.translate.getTranslatedMessage('Password must contain atleast ') + this.minlength + this.translate.getTranslatedMessage(' Characters');
      this.maxLengthError = this.translate.getTranslatedMessage('Maximum Password length is ') + this.maxlength + this.translate.getTranslatedMessage(' chars');
      this.loader.hideLoader();
      this.setPasswordPolicyValidators();
    }).catch((ex) => {
      this.loader.hideLoader();
      console.log(ex);
    });
  }

  // Set Password policy validators to Control
  setPasswordPolicyValidators() {
    if (this.passwordPolicy.lowerAndUpperCase) {
      if (this.minlength && this.maxlength > 0) {
        if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars > 0) {
          this.changePasswordForm.get('password').setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength), PasswordPolicyValidation.RequireLowerandUpper(), PasswordPolicyValidation.RequireDigits(this.passwordPolicy.noOfDigits), PasswordPolicyValidation.RequireSpecialChars(this.passwordPolicy.noOfSpecialChars)]);
        } else if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars <= 0) {
          this.changePasswordForm.get('password').setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength), PasswordPolicyValidation.RequireLowerandUpper(), PasswordPolicyValidation.RequireDigits(this.passwordPolicy.noOfDigits)]);
        } else if (this.passwordPolicy.noOfDigits <= 0 && this.passwordPolicy.noOfSpecialChars > 0) {
          this.changePasswordForm.get('password').setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength), PasswordPolicyValidation.RequireLowerandUpper(), PasswordPolicyValidation.RequireSpecialChars(this.passwordPolicy.noOfSpecialChars)]);
        } else {
          this.changePasswordForm.get('password').setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength), PasswordPolicyValidation.RequireLowerandUpper()]);
        }
      } else {
        if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars > 0) {
          this.changePasswordForm.get('password').setValidators([PasswordPolicyValidation.RequireLowerandUpper(), PasswordPolicyValidation.RequireDigits(this.passwordPolicy.noOfDigits), PasswordPolicyValidation.RequireSpecialChars(this.passwordPolicy.noOfSpecialChars)]);
        } else if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars <= 0) {
          this.changePasswordForm.get('password').setValidators([PasswordPolicyValidation.RequireLowerandUpper(), PasswordPolicyValidation.RequireDigits(this.passwordPolicy.noOfDigits)]);
        } else if (this.passwordPolicy.noOfDigits <= 0 && this.passwordPolicy.noOfSpecialChars > 0) {
          this.changePasswordForm.get('password').setValidators([PasswordPolicyValidation.RequireLowerandUpper(), PasswordPolicyValidation.RequireSpecialChars(this.passwordPolicy.noOfSpecialChars)]);
        } else {
          this.changePasswordForm.get('password').setValidators(PasswordPolicyValidation.RequireLowerandUpper());
        }
      }
    } else {
      if (this.minlength && this.maxlength > 0) {
        if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars > 0) {
          this.changePasswordForm.get('password').setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength), PasswordPolicyValidation.RequireDigits(this.passwordPolicy.noOfDigits), PasswordPolicyValidation.RequireSpecialChars(this.passwordPolicy.noOfSpecialChars)]);
        } else if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars <= 0) {
          this.changePasswordForm.get('password').setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength), PasswordPolicyValidation.RequireDigits(this.passwordPolicy.noOfDigits)]);
        } else if (this.passwordPolicy.noOfDigits <= 0 && this.passwordPolicy.noOfSpecialChars > 0) {
          this.changePasswordForm.get('password').setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength), PasswordPolicyValidation.RequireSpecialChars(this.passwordPolicy.noOfSpecialChars)]);
        } else {
          this.changePasswordForm.get('password').setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength)]);
        }
      } else {
        if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars > 0) {
          this.changePasswordForm.get('password').setValidators([PasswordPolicyValidation.RequireDigits(this.passwordPolicy.noOfDigits), PasswordPolicyValidation.RequireSpecialChars(this.passwordPolicy.noOfSpecialChars)]);
        } else if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars <= 0) {
          this.changePasswordForm.get('password').setValidators(PasswordPolicyValidation.RequireDigits(this.passwordPolicy.noOfDigits));
        } else if (this.passwordPolicy.noOfDigits <= 0 && this.passwordPolicy.noOfSpecialChars > 0) {
          this.changePasswordForm.get('password').setValidators(PasswordPolicyValidation.RequireSpecialChars(this.passwordPolicy.noOfSpecialChars));
        } else {

        }
      }
    }
  }

  changePassword(formValue: any, valid: boolean) {
    let formValues = formValue.value;
    this.submitted = true;
    let user: any = {};
    if (this.identityId != 'newUser') {
      user.old_password = formValues.old_password;
      user.new_password = formValues.password;
      user.confirm_password = formValues.password_echo;
      user.identityId = this.identityId;
      this.loader.showLoader();
      this.changePasswordService.changePassword(user, this.token).then((response: any) => {
        this.submitted = false;
        if (response.status === 400) {
          this.isChangePasswordError = true;
          this.changePasswordError = this.translate.getTranslatedMessage('Given old password is incorrect!');
          this.loader.hideLoader();
        } else {
          StorageHelper.deleteAll();
          this.loader.hideLoader();
          this.changePasswordService.logoutUser(this.token);
          this.router.navigate(['/reset_success']);
        }
      }).catch((ex) => {
        this.submitted = false;
        this.loader.hideLoader();
        this.isChangePasswordError = true;
        this.changePasswordError = this.translate.getTranslatedMessage('There was an error changing your password. Try again later!');
      });
    } else {
      if (valid) {
        this.submitted = false;
        user.old_password = formValues.old_password;
        user.new_password = formValues.password;
        user.confirm_password = formValues.password_echo;
        user.loginSettingsId = this.loginSettingsId;
        this.changePasswordService.firstTimeChangePassword(user);
      } else {
      }

    }
  }

}
