import { Component, OnInit } from '@angular/core';
import { CidaasService } from 'src/app/services/cidaas.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {

  // variables
  requestId: string;
  sub: string;
  client_id: string;
  consent_name: string;
  consent_version: number;
  consent_id: string;
  consent_version_id: string;
  track_id: string;
  consentResponse: any;
  consentScopes: any[] = [];
  isScopePresent: boolean = false;

  constructor(private cidaasService: CidaasService, private activatedRoute: ActivatedRoute, private router: Router, public loader: LoaderService) {
    this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
    this.track_id = this.activatedRoute.snapshot.queryParams.track_id;
    this.sub = this.activatedRoute.snapshot.queryParams.sub;
    this.client_id = this.activatedRoute.snapshot.queryParams.client_id;
    this.consent_name = this.activatedRoute.snapshot.queryParams.consent_name;
    this.consent_version = this.activatedRoute.snapshot.queryParams.consent_version;
    this.consent_id = this.activatedRoute.snapshot.queryParams.consent_id;
    this.consent_version_id = this.activatedRoute.snapshot.queryParams.consent_version_id;
  }

  ngOnInit() {
    this.getConsentDetails();
  }

  getConsentDetails() {
    let options: any = {
      sub: this.sub,
      client_id: this.client_id,
      consent_id: this.consent_id,
      consent_version_id: this.consent_version_id
    };
    this.loader.showLoader();
    this.cidaasService.getConsentDetails(options).then((response) => {
      this.consentResponse = response;
      if (!this.consent_name) {
        this.consent_name = this.consentResponse.consent_name;
      }
      if (this.consentResponse.scopes && this.consentResponse.scopes.length > 0) {
        this.consentScopes = this.consentResponse.scopes;
        this.isScopePresent = true;
      }
      this.loader.hideLoader();
    }).catch((ex) => {
      this.loader.hideLoader();
      console.log(ex);
    });
  }

  acceptConsent() {
    let options: any = {
      client_id: this.client_id,
      consent_id: this.consent_id,
      sub: this.sub,
      consent_version_id: this.consent_version_id
    };
    this.loader.showLoader();
    this.cidaasService.acceptConsent(options).then((response: any) => {
      this.loader.hideLoader();
      this.consentContinue();
    }).catch((ex) => {
      this.loader.hideLoader();
      console.log(ex);
    });
  }

  consentContinue() {
    let options: any = {
      client_id: this.client_id,
      track_id: this.track_id,
      version: this.consent_version_id,
      sub: this.sub,
      name: this.consent_name
    };
    this.cidaasService.consentContinue(options);
  }

}
