import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CidaasService, TranslationService } from '../../services';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  // Variables
  public loginFormGroup: FormGroup;
  requestId: string;
  errorMessage: string;
  error: string;
  isLoginError: boolean = false;
  submitted: boolean = false;


  constructor(@Inject(DOCUMENT) private document: Document, private loginService: CidaasService, private activatedRoute: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private translate: TranslationService) {
    window.document.title = 'login';
  }

  ngOnInit() {
    this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
    this.error = this.activatedRoute.snapshot.queryParams.error;
    if (!this.requestId) {
      this.getRequestId();
    }
    if (this.error) {
      this.isLoginError = true;
      this.errorMessage = this.translate.getTranslatedMessage('Given e-mail or password is invalid');
    }
    this.createNewLoginFormGroup();
  }

  // Get Request Id (If Request Id is not came in URL)
  getRequestId() {
    if(!this.activatedRoute.snapshot.queryParams['requestId']){
      console.log("Direct Entry...redirecting!");
      this.router.ngOnDestroy();
      window.location.href = environment.auth_request_redirect;
    }
    this.loginService.getRequestId().then((response: any) => {
      if (response.success && response.data) {
        this.requestId = response.data.requestId;
      } else if (!response.success && response.error) {
        this.isLoginError = true;
        this.errorMessage = response.error.error;
      } else {
        this.isLoginError = true;
        this.errorMessage = 'requestId cannot be empty or null';
      }
    }).catch((error: any) => {
      console.log(error);
    });
  }

  // Auto Prefill fix
  fixAutoFill(usr: Event, pwd: string) {
    if (usr) { this.loginFormGroup.get('email').setValue((usr.target as HTMLInputElement).value) }
    if (pwd) { this.loginFormGroup.get('password').setValue(pwd) }
  }

  // Create New Login Form
  createNewLoginFormGroup() {
    this.loginFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  // Login
  login(formValue: any) {
    this.submitted = true;
    if (formValue.valid) {
      let loginPayload = formValue.value;
      loginPayload['requestId'] = this.requestId;
      this.submitted = false;
      this.loginService.login(loginPayload);
    } else {
      console.error('Login Form Invalid!');
    }
  }

}
