import { StorageHelper } from './storage-helper';

export class LocaleHelper {

    // variables
    static language: string;
    static localnavigator: any = <any>window.navigator;

    // Get Browser Language
    static getBrowserLanguage(): string {
        let locale = StorageHelper.getData("ui_locales");
        if (locale && locale != "") {
            let list: string[] = locale.split(",");
            if (list && list.length > 0) {
                this.language = list[0];
            } else {
                this.language = 'de';
            }
        } else {
            this.language = this.localnavigator.language;
            if (!this.language) {
                this.language = this.localnavigator.systemLanguage;
            }
            if (!this.language) {
                this.language = 'de';
            }
        }
        return this.language;
    }
}