import { Routes } from '@angular/router';
import { RegisterComponent } from '../views/register/register.component';
import { LoginComponent } from '../views/login/login.component';
import { MfaComponent } from '../views/mfa/mfa.component';
import { MfaVerifyComponent } from '../views/mfa-verify/mfa-verify.component';
import { ExtraComponent } from '../views/extra/extra.component';
import { ForgetPasswordComponent } from '../views/forget-password/forget-password.component';
import { ForgetSuccessComponent } from '../views/forget-success/forget-success.component';
import { ResetPasswordComponent } from '../views/reset-password/reset-password.component';
import { ChangePasswordComponent } from '../views/change-password/change-password.component';
import { ConsentComponent } from '../views/consent/consent.component';
import { ScopeConsentComponent } from '../views/scope-consent/scope-consent.component';
import { RegisterSuccessComponent } from '../views/register-success/register-success.component';
import { VerificationComponent } from '../views/verification/verification.component';
import { ResetSuccessComponent } from '../views/reset-success/reset-success.component';
import { UnverifiedUserComponent } from '../views/unverified-user/unverified-user.component';

export const routes: Routes = [ //rehau-kbox-ui
    { path: 'login', component: LoginComponent },
    { path: 'rehau-kbox-ui/login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'rehau-kbox-ui/register', component: RegisterComponent },
    { path: 'extra', component: ExtraComponent },
    { path: 'rehau-kbox-ui/extra', component: ExtraComponent },
    { path: 'reset/:requestId', component: ForgetPasswordComponent },
    { path: 'rehau-kbox-ui/reset/:requestId', component: ForgetPasswordComponent },
    { path: 'reset_success', component: ForgetSuccessComponent },
    { path: 'rehau-kbox-ui/reset_success', component: ForgetSuccessComponent },
    { path: 'unverified_user', component: UnverifiedUserComponent },
    { path: 'rehau-kbox-ui/unverified_user', component: UnverifiedUserComponent },
    { path: 'forgot_success', component: ResetSuccessComponent },
    { path: 'rehau-kbox-ui/forgot_success', component: ResetSuccessComponent },
    { path: 'resetpassword', component: ResetPasswordComponent },
    { path: 'rehau-kbox-ui/resetpassword', component: ResetPasswordComponent },
    { path: 'changepassword', component: ChangePasswordComponent },
    { path: 'rehau-kbox-ui/changepassword', component: ChangePasswordComponent },
    { path: 'mfa', component: MfaComponent },
    { path: 'rehau-kbox-ui/mfa', component: MfaComponent },
    { path: 'consent', component: ConsentComponent },
    { path: 'rehau-kbox-ui/consent', component: ConsentComponent },
    { path: 'scope_consent', component: ScopeConsentComponent },
    { path: 'rehau-kbox-ui/scope_consent', component: ScopeConsentComponent },
    { path: 'reg_success', component: RegisterSuccessComponent },
    { path: 'rehau-kbox-ui/reg_success', component: RegisterSuccessComponent },
    { path: 'verification', component: VerificationComponent },
    { path: 'rehau-kbox-ui/verification', component: VerificationComponent },
    { path: 'mfaverify/:statusId/:type/:sub/:trackId/:requestId', component: MfaVerifyComponent },
    { path: 'rehau-kbox-ui/mfaverify/:statusId/:type/:sub/:trackId/:requestId', component: MfaVerifyComponent },
    { path: '', redirectTo: '/login', pathMatch: 'full' }
]
