import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParseurlService {

  constructor() { }

  parseFragment(fragment: string): any {
    var paresedFragment = {};
    var vals = fragment.split("&");
    for (var i = 0; i < vals.length; i++) {
      var values = vals[i].split("=");
      if (values.length == 2) {
        paresedFragment[values[0]] = values[1];
      }
    }
    return paresedFragment;
  }

  findValueInFragment(fragment: string, key: string, key1: string = ""): string {
    if (fragment.charAt(0) === '?' || fragment.charAt(0) === '#') {
      fragment = fragment.substr(1);
    }
    if (this.parseFragment(fragment)[key]) {
      return this.parseFragment(fragment)[key];
    }
    else if (this.parseFragment(fragment)[key1]) {
      return this.parseFragment(fragment)[key1];
    }
    else {
      return undefined;
    }
  }
}
