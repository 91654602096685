import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageHelper } from 'src/app/config';

@Component({
  selector: 'app-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss']
})
export class RegisterSuccessComponent implements OnInit {

  // Variables
  requestId: string;

  constructor(private activatedRoute: ActivatedRoute) {
    window.document.title = 'Register Success';
  }

  ngOnInit() {
    this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
    if (StorageHelper.getData('mail_triggered')) {
      StorageHelper.deleteData('mail_triggered');
    }
  }

}
