import { AbstractControl, ValidatorFn } from "@angular/forms";

export class InputFieldValidator {

    static ValidateText(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            let text = control.value;
            if (!text.match('^[ +a-zA-Z0-9_.@-äüöÄÜÖß]+$')) {
                return { 'InvalidText': true }
            } else {
                return null;
            }
        }
    }

    static ValidateNumber(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            let text = control.value;
            if (!text.match('^[ +0-9]+$')) {
                return { 'InvalidNumber': true }
            } else {
                return null;
            }
        }
    }

    static ValidateURL(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (control.value && (!(control.value.toString().trim().startsWith("http://")) && !(control.value.toString().trim().startsWith("https://")))) {
                return { 'InvalidURL': true };
            }
            return null;
        }
    }

    static ValidateCheckBox(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            let checkBox: any[] = control.value;
            let checkBoxVal: any;
            checkBox.forEach((val) => {
                checkBoxVal = val;
            })
            if (!checkBoxVal) {
                return { 'Required': true }
            } else {
                return null;
            }
        }
    }
}