import { Injectable } from '@angular/core';
import * as data from '../../assets/lang/de.json';
import * as dataen from '../../assets/lang/en.json';
import * as datait from '../../assets/lang/it.json';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  // variables
  language: string;
  localnavigator: any = <any>window.navigator;
  enString: any = dataen.default;
  deString: any = data.default;
  itString: any = datait.default;

  constructor() {
    this.language = this.localnavigator.language;
    if (!this.language) {
      this.language = this.localnavigator.systemLanguage;
    }
    if (!this.language) {
      this.language = 'en';
    }
  }

  getTranslatedMessage(message) {
    let dataToReturn: any;
    if (this.language.indexOf('de') >= 0) {
      dataToReturn = this.deString[message];
    } else if (this.language.indexOf('en') >= 0) {
      dataToReturn = this.enString[message];
    } else if (this.language.indexOf('it') >= 0) {
      dataToReturn = this.itString[message];
    } else {
      dataToReturn = this.enString[message];
    }

    if (dataToReturn == null) {
      return message;
    }
    if (typeof dataToReturn == 'string') {
      dataToReturn = ' ' + dataToReturn + ' ';
    }
    return dataToReturn;
  }
}
