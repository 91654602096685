import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from './services/communication.service';
import { ParseurlService } from './services/parseurl.service';
import { StorageHelper } from './config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  // variables
  browserLang = '';
  show: boolean = false;
  ui_locales: string;
  currentLocale: any;
  urlLocale: any;
  client_id: string;

  constructor(private translate: TranslateService, private communicationService: CommunicationService, private parseUrl: ParseurlService) {
    this.currentLocale = StorageHelper.getData("ui_locales");
    this.urlLocale = this.parseUrl.findValueInFragment(location.search, "ui_locales");
    this.client_id = this.parseUrl.findValueInFragment(location.search, "client_id");
    if (this.client_id) {
      StorageHelper.saveData("client_id", this.client_id);
    }
  }

  ngAfterViewInit(): void {
    this.communicationService.on('show', (load: boolean) => {
      this.show = load;
    });
  }

  ngOnInit(): void {
    this.browserLang = this.translate.getBrowserLang();
    this.translate.setDefaultLang(this.browserLang);
    if (!this.currentLocale) {
      this.ui_locales = this.urlLocale;
    } else {
      if (this.currentLocale !== this.urlLocale) {
        this.ui_locales = this.urlLocale;
      } else {
        this.ui_locales = this.currentLocale;
      }
    }
    if (this.ui_locales && this.ui_locales != "") {
      StorageHelper.saveData("ui_locales", this.ui_locales);
      let list: string[] = this.ui_locales.split(",");
      if (list && list.length > 0) {
        let locale = list[0];
        if (locale.search("en") != -1) {
          this.translate.use("en");
        } else if (locale.search("de") != -1) {
          this.translate.use("de");
        } else if (locale.search("it") != -1) {
          this.translate.use("it");
        } else {
          this.translate.use("en");
        }
      }
    } else {
      if (this.browserLang.search("de") != -1 || this.browserLang.search("it") != -1) {
        this.translate.use(this.browserLang);
      } else {
        this.translate.use("en");
      }
    }
  }

}
