import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldConfig, PasswordPolicy } from '../../models';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { CidaasService, LoaderService, TranslationService } from '../../services';
import { LocaleHelper, PasswordValidation, PasswordPolicyValidation } from 'src/app/config';
import { InputFieldValidator } from 'src/app/config/input-validation';
import { isArray } from 'util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-extra',
  templateUrl: './extra.component.html',
  styleUrls: ['./extra.component.scss']
})
export class ExtraComponent implements OnInit {

  // variables
  requestId: string;
  trackId: string;
  registrationFields: FieldConfig[] = [];
  registrationForm: FormGroup;
  acceptLanguage: string;
  registrationFieldPayload = {
    acceptlanguage: '',
    requestId: ''
  }
  registrationFieldResponse: any[] = [];
  passwordPolicy: PasswordPolicy;
  minlength: number = 0;
  maxlength: number = 100;
  afterRegisterResponse: any;
  isRegisterError: boolean = false;
  registerError: string = '';
  countryNumber: string;
  specialCharactersError: string;
  digitsRequiredError: string;
  LowerAndUpperCaseError: string;
  countryName: string;
  customFields: any = {};
  checkboxOptions: any[] = [];
  submitted: boolean = false;
  missingFields: string[] = [];

  constructor(private formBuilder: FormBuilder, private router: Router, private loader: LoaderService, private activatedRoute: ActivatedRoute, private registrationService: CidaasService, private translate: TranslationService) {
    window.document.title = 'Additional Information';
    this.acceptLanguage = LocaleHelper.getBrowserLanguage();
    this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
    this.trackId = this.activatedRoute.snapshot.queryParams.trackId;
  }

  ngOnInit() {
    this.createRegistrationForm();
    if (this.trackId && this.requestId) {
      this.getMissingFields();
    } else {
      this.router.navigate(['/welcome']);
      console.error('Invalid trackId or trackId not found !');
    }
    this.registrationFieldPayload = {
      acceptlanguage: this.acceptLanguage,
      requestId: this.requestId
    }
    this.getPasswordPolicy();
  }


  // Create New Registration Form
  createRegistrationForm() {
    this.registrationForm = this.formBuilder.group({});
  }

  // Get Password policy
  getPasswordPolicy() {
    this.loader.showLoader();
    this.registrationService.getPasswordPolicy().then((response: any) => {
      this.passwordPolicy = response.data;
      if (this.passwordPolicy.minimumLength > 0) {
        this.minlength = this.passwordPolicy.minimumLength;
      }
      if (this.passwordPolicy.maximumLength > 0) {
        this.maxlength = this.passwordPolicy.maximumLength;
      }
      this.specialCharactersError = this.translate.getTranslatedMessage('Password must contain atleast ') + this.passwordPolicy.noOfSpecialChars + this.translate.getTranslatedMessage(' Special Characters');
      this.digitsRequiredError = this.translate.getTranslatedMessage('Password must contain atleast ') + this.passwordPolicy.noOfDigits + this.translate.getTranslatedMessage(' Digits');
      this.LowerAndUpperCaseError = this.translate.getTranslatedMessage("Password must contain atleast one uppercase and one lower case Character");
      this.loader.hideLoader();
      this.getRegistrationFields();
    }).catch((ex) => {
      this.loader.hideLoader();
      this.getRegistrationFields();
    });
  }

  // get missing fields lists
  getMissingFields() {
    this.loader.showLoader();
    this.registrationService.getMissingFields(this.trackId).then((response: any) => {
      this.loader.hideLoader();
      if (response.data) {
        this.missingFields = response.data.meta_data.missing_fields;
      } else {
        this.missingFields = [];
      }
    }).catch((ex) => {
      this.loader.hideLoader();
      console.log(ex);
    })
  }

  getRegistrationFields() {
    this.loader.showLoader();
    this.registrationService.getRegistrationSetup(this.registrationFieldPayload).then((response: any) => {
      this.loader.hideLoader();
      this.registrationFieldResponse = response.sort((a, b) => { return a.order - b.order });
      // this.registrationFieldResponse.push(this.privacy);
      if (this.registrationFieldResponse && this.registrationFieldResponse.length > 0) {
        let actualFieldsList: any[] = [];
        this.registrationFieldResponse.forEach((field) => {
          this.missingFields.forEach((missingfield) => {
            if (field.fieldKey === missingfield) {
              actualFieldsList.push(field);
            }
          })
        });
        this.registrationFields = [];
        if (actualFieldsList && actualFieldsList.length > 0) {
          actualFieldsList.forEach((field, index) => {
            this.registrationFields.push(this.setFormFields(field, index));
          })
          this.registrationFields.forEach((field) => {
            this.createControl(field);
          });
        }
      } else {
        this.loader.hideLoader();
        this.router.navigate(['/welcome'], { queryParams: { error: 'registration fields not found' } });
      }
    }).catch((ex) => {
      this.loader.hideLoader();
      console.log(ex);
    })
  }

  // Assign value to Form Fields
  setFormFields(field: any, index: number): FieldConfig {
    let tempField: FieldConfig = {} as FieldConfig;
    tempField.validations = {};
    tempField.inputType = field.dataType;
    tempField.type = field.fieldType;
    tempField.key = field.fieldKey;
    tempField.internal = field.internal ? field.internal : false;
    tempField.readOnly = field.readOnly;
    tempField.label = field.localeText ? field.localeText.name ? field.localeText.name : 'Label' + index : 'Label' + index;
    tempField.name = field.localeText ? field.localeText.name ? field.localeText.name : 'Name' + index : 'Name' + index;
    tempField.value = '';
    if (field.dataType === 'SELECT') {
      tempField.options = field.localeText ? field.localeText.attributes ? field.localeText.attributes : field.localeText.attributes : '';
    } else if (field.dataType == 'CHECKBOX') {
      tempField.options = field.localeText ? field.localeText.attributes ? field.localeText.attributes : field.localeText.attributes : '';
      tempField.options.forEach((val) => {
        this.checkboxOptions.push(val);
      })
    } else if (field.dataType == "CONSENT") {
      tempField.collections = field.localeText ? field.localeText.consentLabel ? field.localeText.consentLabel : field.localeText.consentLabel : null;
    }
    if (field.required) {
      tempField.isRequired = true;
      let required = {
        name: 'required',
        validator: Validators.required,
        message: field.localeText ? field.localeText.required ? field.localeText.required : 'This field is required' : 'This field is required'
      }
      tempField.validations.required = required;
    }
    if (field.dataType === 'EMAIL') {
      let email = {
        name: 'email',
        validator: Validators.email,
        message: this.translate.getTranslatedMessage('Please enter valid E-mail')
      }
      tempField.validations.email = email;
    }
    if (field.fieldDefinition && field.fieldDefinition.maxLength && field.fieldKey != 'password') {
      let maxlength = {
        name: 'maxlength',
        validator: Validators.maxLength(field.fieldDefinition.maxLength),
        maxlength: field.fieldDefinition.maxLength,
        message: field.localeText.maxLength
      }
      tempField.validations.maxlength = maxlength;
    } else if (field.fieldKey == 'password' && this.passwordPolicy.minimumLength && this.passwordPolicy.maximumLength > 0) {
      let minlength = {
        name: 'minlength',
        validator: Validators.minLength(this.passwordPolicy.minimumLength),
        minlength: this.passwordPolicy.minimumLength,
        message: this.translate.getTranslatedMessage('Password must contain atleast ') + this.passwordPolicy.minimumLength + this.translate.getTranslatedMessage(' Characters')
      }
      tempField.validations.minlength = minlength;
      let maxlength = {
        name: 'maxlength',
        validator: Validators.maxLength(this.passwordPolicy.maximumLength),
        maxLength: this.passwordPolicy.maximumLength,
        message: this.translate.getTranslatedMessage('Maximum Password length is ') + this.passwordPolicy.maximumLength + this.translate.getTranslatedMessage(' chars')

      }
      tempField.validations.maxlength = maxlength;
    } else if (field.fieldDefinition && field.fieldDefinition.maxLength && field.key == 'password' && (this.passwordPolicy.maximumLength <= 0 || !this.passwordPolicy.maximumLength)) {
      let maxlength = {
        name: 'maxlength',
        validator: Validators.maxLength(field.fieldDefinition.maxLength),
        maxlength: field.fieldDefinition.maxLength,
        message: field.localeText.maxLength
      }
      tempField.validations.maxlength = maxlength;
    }
    if (field.localeText && field.localeText.matchWith && field.fieldDefinition && field.fieldDefinition.matchWith) {
      let MatchPassword = {
        name: 'MatchPassword',
        validator: 'MatchPassword',
        matchwith: field.fieldDefinition.matchWith,
        message: field.localeText.matchWith
      }
      tempField.validations.MatchPassword = MatchPassword;
    }
    return tempField;
  }

  // Create Dynamic Control
  createControl(field: any) {
    if (field.inputType != 'CHECKBOX') {
      const control = this.formBuilder.control(field.value, this.bindValidations(field.key, field.validations));
      this.registrationForm.addControl(field.key, control);
    } else {
      let CheckboxControl: any;
      if (field.isRequired) {
        CheckboxControl = new FormArray([], InputFieldValidator.ValidateCheckBox());
      } else {
        CheckboxControl = new FormArray([])
      }
      field.options.map((o, i) => {
        const control = new FormControl();
        CheckboxControl.push(control);
      });
      this.registrationForm.addControl(field.key, CheckboxControl);
    }
  }

  // Bind Validations to Control
  bindValidations(key: string, validations: any) {
    if (key !== 'password' && validations) {
      const validList = [];
      Object.keys(validations).forEach((key) => {
        if (validations[key].validator != 'MatchPassword') {
          validList.push(validations[key].validator);
        }
      });
      return Validators.compose(validList);
    } else if (key == 'password' && validations) {
      const validList = [];
      Object.keys(validations).forEach((key) => {
        if (validations[key].validator != 'MatchPassword') {
          validList.push(validations[key].validator);
        }
      });
      if (this.passwordPolicy) {
        if (this.passwordPolicy.lowerAndUpperCase) {
          validList.push(PasswordPolicyValidation.RequireLowerandUpper());
        }
        if (this.passwordPolicy.noOfDigits > 0) {
          validList.push(PasswordPolicyValidation.RequireDigits(this.passwordPolicy.noOfDigits));
        }
        if (this.passwordPolicy.noOfSpecialChars > 0) {
          validList.push(PasswordPolicyValidation.RequireSpecialChars(this.passwordPolicy.noOfSpecialChars));
        }
        return Validators.compose(validList);
      } else {
        return Validators.compose(validList);
      }
    }
  }

  register(formValue: any) {
    this.loader.showLoader();
    let formValues = formValue.value;
    let payload: any = {};
    let reqHeaders: any = {};
    let customFields: any = {};
    this.submitted = true;
    this.registrationFields.forEach((field) => {
      field.value = formValues[field.key];
      if (isArray(field.value)) {
        field.value.forEach((val, i) => {
          if (!val) {
            field.value.splice(i, 1);
          } else {
            field.value[i] = field.options[i].value;
          }
        })
      }
      if (field.key === 'NeaSmart_Installer' && isArray(field.value) && field.value.length > 0) {
        payload['roles'] = ['User_NeaSmart_Installer'];
      }
      if (field.type === 'SYSTEM') {
        payload[field.key] = field.value;
      } else {
        customFields[field.key] = {
          key: field.key,
          value: field.value,
          readOnly: field.readOnly,
          dataType: field.inputType,
          internal: field.internal,
          lastUpdateFrom: "SELF"
        }
      }
    });
    payload['customFields'] = customFields;
    payload['provider'] = "self";
    reqHeaders['requestId'] = this.requestId;
    if (this.trackId) {
      reqHeaders['trackId'] = this.trackId;
    }
    this.registrationService.progressiveRegisterUser(payload, reqHeaders, '').then((response: any) => {
      this.loader.hideLoader();
      if (response && response.data) {
        // this.afterRegisterResponse = response.data;
        // this.router.navigate(['/verification'], { queryParams: { 'requestId': this.requestId, 'sub': this.afterRegisterResponse.sub } })
        var loginFormElement = document.getElementsByName('register_user')[0] as HTMLFormElement;
        var postLoginURL = environment.loginBaseURL + "/login-srv/precheck/continue/" + this.trackId;
        loginFormElement.action = postLoginURL;
        loginFormElement.method = 'post';
        loginFormElement.submit();
      } else {
        this.submitted = false;
        if (response.status == 409) {
          this.isRegisterError = true;
          this.registerError = this.translate.getTranslatedMessage('User Already Exist');
        } else {
          this.isRegisterError = true;
          this.registerError = this.translate.getTranslatedMessage('Error Occured');
        }
      }
    }).catch((ex) => {
      this.loader.hideLoader();
      console.log(ex);
      this.submitted = false;
      this.isRegisterError = true;
      this.registerError = this.translate.getTranslatedMessage('Error Occured');
    });
  }

}
