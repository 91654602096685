import { Component, OnInit } from '@angular/core';
import { CidaasService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { StorageHelper } from 'src/app/config';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {

  // Variables
  requestId: string;
  sub: string;

  constructor(private cidaasService: CidaasService, private activatedRoute: ActivatedRoute) {
    this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
    this.sub = this.activatedRoute.snapshot.queryParams.sub;
  }

  ngOnInit() {
    if (StorageHelper.getData('mail_triggered')) {
      let currentTime = (new Date()).getTime();
      let cookie_time = Number(StorageHelper.getData('mail_triggered'));
      if ((currentTime - cookie_time) > 1000 * 600) {
        this.initiateAccountVerification();
      }
    } else {
      this.initiateAccountVerification();
    }
  }

  initiateAccountVerification() {
    var options: any = {};
    options.requestId = this.requestId;
    options.sub = this.sub;
    let cookie_time = (new Date()).getTime().toString();
    StorageHelper.saveData('mail_triggered', cookie_time);
    this.cidaasService.initiateAccountVerification(options);
  }

}
