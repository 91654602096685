import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CidaasService, TranslationService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordPolicy } from '../../models/index';
import { PasswordPolicyValidation, PasswordValidation } from '../../config';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  // variables
  requestId: string;
  passwordPolicy: PasswordPolicy;
  resetPasswordForm: FormGroup;
  minlength: number = 0;
  maxlength: number = 100;
  exchangeId: string;
  rprq: string;
  specialCharactersError: string;
  LowerAndUpperCaseError: string;
  digitsRequiredError: string;
  maxLengthError: string;
  minLengthError: string;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute, private resetPasswordService: CidaasService, private translate: TranslationService) {
    window.document.title = 'Reset Password';
    this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
    this.exchangeId = this.activatedRoute.snapshot.queryParams.exchangeid;
    this.rprq = this.activatedRoute.snapshot.queryParams.rprq;
  }

  ngOnInit() {
    this.createNewResetPasswordForm();
    this.getPasswordPolicy();
  }

  // create new reset password form
  createNewResetPasswordForm() {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      password_echo: ['', [Validators.required]]
    });
    this.resetPasswordForm.setValidators(PasswordValidation.MatchPassword);
  }

  // Get Password policy
  getPasswordPolicy() {
    this.resetPasswordService.getPasswordPolicy().then((response: any) => {
      this.passwordPolicy = response.data;
      if (this.passwordPolicy.minimumLength > 0) {
        this.minlength = this.passwordPolicy.minimumLength;
      }
      if (this.passwordPolicy.maximumLength > 0) {
        this.maxlength = this.passwordPolicy.maximumLength;
      }
      this.specialCharactersError = this.translate.getTranslatedMessage('Password must contain atleast ') + this.passwordPolicy.noOfSpecialChars + this.translate.getTranslatedMessage(' Special Characters');
      this.digitsRequiredError = this.translate.getTranslatedMessage('Password must contain atleast ') + this.passwordPolicy.noOfDigits + this.translate.getTranslatedMessage(' Digits');
      this.LowerAndUpperCaseError = this.translate.getTranslatedMessage('Password must contain atleast one uppercase and one lower case Character');
      this.minLengthError = this.translate.getTranslatedMessage('Password must contain atleast ') + this.minlength + this.translate.getTranslatedMessage(' Characters');
      this.maxLengthError = this.translate.getTranslatedMessage('Maximum Password length is ') + this.maxlength + this.translate.getTranslatedMessage(' chars');
      this.setPasswordPolicyValidators();
    }).catch((ex) => {
      console.log(ex);
    });
  }

  // Set Password policy validators to Control
  setPasswordPolicyValidators() {
    if (this.passwordPolicy.lowerAndUpperCase) {
      if (this.minlength && this.maxlength > 0) {
        if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars > 0) {
          this.resetPasswordForm.get('password').setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength), PasswordPolicyValidation.RequireLowerandUpper(), PasswordPolicyValidation.RequireDigits(this.passwordPolicy.noOfDigits), PasswordPolicyValidation.RequireSpecialChars(this.passwordPolicy.noOfSpecialChars)]);
        } else if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars <= 0) {
          this.resetPasswordForm.get('password').setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength), PasswordPolicyValidation.RequireLowerandUpper(), PasswordPolicyValidation.RequireDigits(this.passwordPolicy.noOfDigits)]);
        } else if (this.passwordPolicy.noOfDigits <= 0 && this.passwordPolicy.noOfSpecialChars > 0) {
          this.resetPasswordForm.get('password').setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength), PasswordPolicyValidation.RequireLowerandUpper(), PasswordPolicyValidation.RequireSpecialChars(this.passwordPolicy.noOfSpecialChars)]);
        } else {
          this.resetPasswordForm.get('password').setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength), PasswordPolicyValidation.RequireLowerandUpper()]);
        }
      } else {
        if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars > 0) {
          this.resetPasswordForm.get('password').setValidators([PasswordPolicyValidation.RequireLowerandUpper(), PasswordPolicyValidation.RequireDigits(this.passwordPolicy.noOfDigits), PasswordPolicyValidation.RequireSpecialChars(this.passwordPolicy.noOfSpecialChars)]);
        } else if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars <= 0) {
          this.resetPasswordForm.get('password').setValidators([PasswordPolicyValidation.RequireLowerandUpper(), PasswordPolicyValidation.RequireDigits(this.passwordPolicy.noOfDigits)]);
        } else if (this.passwordPolicy.noOfDigits <= 0 && this.passwordPolicy.noOfSpecialChars > 0) {
          this.resetPasswordForm.get('password').setValidators([PasswordPolicyValidation.RequireLowerandUpper(), PasswordPolicyValidation.RequireSpecialChars(this.passwordPolicy.noOfSpecialChars)]);
        } else {
          this.resetPasswordForm.get('password').setValidators(PasswordPolicyValidation.RequireLowerandUpper());
        }
      }
    } else {
      if (this.minlength && this.maxlength > 0) {
        if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars > 0) {
          this.resetPasswordForm.get('password').setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength), PasswordPolicyValidation.RequireDigits(this.passwordPolicy.noOfDigits), PasswordPolicyValidation.RequireSpecialChars(this.passwordPolicy.noOfSpecialChars)]);
        } else if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars <= 0) {
          this.resetPasswordForm.get('password').setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength), PasswordPolicyValidation.RequireDigits(this.passwordPolicy.noOfDigits)]);
        } else if (this.passwordPolicy.noOfDigits <= 0 && this.passwordPolicy.noOfSpecialChars > 0) {
          this.resetPasswordForm.get('password').setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength), PasswordPolicyValidation.RequireSpecialChars(this.passwordPolicy.noOfSpecialChars)]);
        } else {
          this.resetPasswordForm.get('password').setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength)]);
        }
      } else {
        if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars > 0) {
          this.resetPasswordForm.get('password').setValidators([PasswordPolicyValidation.RequireDigits(this.passwordPolicy.noOfDigits), PasswordPolicyValidation.RequireSpecialChars(this.passwordPolicy.noOfSpecialChars)]);
        } else if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars <= 0) {
          this.resetPasswordForm.get('password').setValidators(PasswordPolicyValidation.RequireDigits(this.passwordPolicy.noOfDigits));
        } else if (this.passwordPolicy.noOfDigits <= 0 && this.passwordPolicy.noOfSpecialChars > 0) {
          this.resetPasswordForm.get('password').setValidators(PasswordPolicyValidation.RequireSpecialChars(this.passwordPolicy.noOfSpecialChars));
        } else {

        }
      }
    }
  }

  changePassword(formValue: any, valid: boolean) {
    this.submitted = true;
    if (valid) {
      let formValues = formValue.value;
      let user: any = {};
      user.password = formValues.password;
      user.confirmPassword = formValues.password_echo;
      user.exchangeId = this.exchangeId;
      user.resetRequestId = this.rprq;
      this.resetPasswordService.resetPassword(user).then((response: any) => {
        this.submitted = false;
        this.router.navigate(['/forgot_success'], { queryParams: { 'requestId': this.requestId } });
      }).catch((ex) => {
        this.submitted = false;
        console.log('Reset Failed');
      })
    } else {

    }

  }
}
