import { AbstractControl, ValidatorFn } from "@angular/forms";
export class PasswordPolicyValidation {

    static RequireDigits(digitCount: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            let password = control.value; // to get value in input tag
            let count = (password.match(/\d/g) || []).length;
            if (Number(count) < digitCount) {
                return { 'MinDigitsRequired': true };
            } else {
                return null;
            }
        }
    }

    static RequireSpecialChars(specialCharsCount: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            let password = control.value; // to get value in input tag
            let count = (password.match(/\W/g) || []).length;
            if (Number(count) < specialCharsCount) {
                return { 'SpecialCharsRequired': true };
            } else {
                return null;
            }
        }
    }

    static RequireLowerandUpper(): ValidatorFn {
        let lowerCasePattern = /([a-z]){1,}/;
        let upperCasePattern = /([A-Z]){1,}/;
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (!control.value.match(lowerCasePattern) || !control.value.match(upperCasePattern)) {
                return { 'LowerAndUpperRequired': true };
            } else {
                return null;
            }
        }
    }
}